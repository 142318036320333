<template>
  <div>
    <div class="cardTitle">社区动态</div>
    <a-divider></a-divider>
    <div class="formbox">
      <a-form-model ref="ruleForm" :model="searchForm" v-bind="layout">
        <a-form-model-item label="内容" prop="content">
          <a-input v-model="searchForm.content" />
        </a-form-model-item>
        <a-form-model-item label="是否公开" prop="isPublic">
          <a-select v-model="searchForm.isPublic">
            <a-select-option
              v-for="item in options.isTrue"
              :key="item.id"
              :value="item.id"
              >{{ item.name }}</a-select-option
            >
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="是否可以评论" prop="isComment">
          <a-select v-model="searchForm.isComment">
            <a-select-option
              v-for="item in options.isTrue"
              :key="item.id"
              :value="item.id"
              >{{ item.name }}</a-select-option
            >
          </a-select>
        </a-form-model-item>
        <a-form-model-item :wrapper-col="{ span: 12, offset: 2 }">
          <a-button type="primary" @click="getData()"> 查询 </a-button>
          <a-button style="margin-left: 10px" @click="reset"> 重置 </a-button>
        </a-form-model-item>
      </a-form-model>
    </div>
    <div class="main">
      <!-- 表格 -->
      <a-table
        :columns="columns"
        :data-source="tableData"
        :pagination="pagination"
        :scroll="{ x: 1800 }"
        @change="handleTableChange"
        :row-selection="{
          selectedRowKeys: selectedRowKeys,
          onChange: selectionChoosed,
        }"
        :row-key="
          (record, index) => {
            return record.id;
          }
        "
      >
        <span slot="action" slot-scope="text, row">
          <a-space>
            <a
              class="ant-dropdown-link"
              @click="detail(row)"
              >详情</a
            >
            <a
              class="ant-dropdown-link"
              v-if="row.isDelete===1"
              @click="del([row.id])"
              >删除</a
            >
            <a
              class="ant-dropdown-link"
              v-if="row.isDelete===0"
              @click="recover([row.id])"
              >恢复</a
            >
          </a-space>
        </span>
      </a-table>
      <div style="margin-bottom: 16px" class="action">
        <!-- 批量操作 -->
        <a-select
          type="primary"
          v-model="activeAction"
          :disabled="!hasSelected"
          :loading="loading"
          style="width: 120px"
          @change="Actions"
          placeholder="请选择操作"
        >
          批量
          <a-select-option v-for="item in ActionsList" :key="item.value">
            {{ item.label }}
          </a-select-option>
        </a-select>
        <span style="margin-left: 8px">
          <template v-if="hasSelected">
            {{ `已选择 ${selectedRowKeys.length} 条` }}
          </template>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { pagination, columns, options } from "./depend/config";
import { dynamicList, dynamicRecover, dynamicDel } from "@/api/operation/dynamic";
export default {
	data() {
		return {
			tableData: [],
			pagination,
			options,
			columns,
			activeAction: undefined,
			loading: false,
			// 选择的index
			selectedRowKeys: [],
			ActionsList: [
				{
					value:1,
					label:'批量删除'
				},
				{
					value:2,
					label:'批量恢复'
				},
			],
			layout: {
				labelCol: { span: 2 },
				wrapperCol: { span: 4 },
			},
			searchForm: {
				content: "",
				isPublic: undefined,
				isComment: undefined,
			},
		};
	},
	mounted() {
		this.getData();
	},
	methods: {
		async getData() {
			let res = await dynamicList({
				...this.searchForm,
				pageNum: this.pagination.current,
				size: this.pagination.pageSize,
			});
			this.tableData = res.data.rows;
			this.pagination.total = res.data.total;
		},
		reset() {
			this.searchForm = {
				content: "",
				isPublic: undefined,
				isComment: undefined,
			}
			this.getData()
		},
		Actions(data) {
			this.activeAction = undefined;
			if(data===1){
				this.del(this.selectedRowKeys)
			}else{
				this.recover(this.selectedRowKeys)
			}
		},
		detail(data){
			this.$router.push({name:'DynamicDetail',params:{data:data.id}})
		},
		del(ids){
			this.$confirm({
				title: "是否删除",
				// okText:'删除',
				// cancelText:'取消',
				icon: "delete",
				onOk: async() => {
					let res = await dynamicDel({dynamicIds:ids})
					if(res.code === 200){
						this.$message.success(res.msg)
						this.getData()
					}else{
						this.$message.error(res.msg)
					}
				},
			});
			
		},
		recover(ids){
			this.$confirm({
				title: "是否恢复",
				// okText:'删除',
				// cancelText:'取消',
				icon: "undo",
				onOk: async() => {
					let res = await dynamicRecover({dynamicIds:ids})
					if(res.code === 200){
						this.$message.success(res.msg)
						this.getData()
					}else{
						this.$message.error(res.msg)
					}
				},
			});
			
		},
		selectionChoosed(data) {
			this.selectedRowKeys = data;
		},
		handleTableChange(pagination) {
			const pager = { ...this.pagination };
			pager.current = pagination.current;
			pager.pageSize = pagination.pageSize;
			this.pagination = pager;
			this.getData();
		},
		handleMenuClick(){}
	},
	computed: {
		hasSelected() {
			return this.selectedRowKeys.length > 0;
		},
	},
};
</script>

<style lang="less" scoped>
.formbox {
  text-align: left;
  background: #f9f9f9;
  padding-top: 25px;
  padding-bottom: 1px;
  margin:24px;
}
</style>