export const pagination = {
	current: 1,
	total: 0,
	pageSize: 10,
	showTotal: (total) => `共 ${total} 条`,
	showSizeChanger: true,
	showQuickJumper: true,
}
export const columns = [
	{
		title: "内容",
		width: "12%",
		dataIndex: "content",
	},
	{
		title: "是否可以评论",
		width: "8%",
		dataIndex: "isComment",
		customRender: function (isComment) {
			switch (isComment) {
			case 1:
				return '是'
			case 0:
				return '否'
			}
		}
	},
	{
		title: "是否公开",
		width: "4%",
		dataIndex: "isPublic",
		customRender: function (isPublic) {
			switch (isPublic) {
			case 1:
				return '是'
			case 0:
				return '否'
			}
		}
	},
	{
		title: "发布人名称",
		width: "12%",
		dataIndex: "createName",
	},
	{
		title: "发布时间",
		width: "12%",
		dataIndex: "createDate",
	},
	{
		title: "点赞数量",
		width: "12%",
		dataIndex: "likes",
	},
	{
		title: "浏览量",
		width: "12%",
		dataIndex: "views",
	},
	{
		title: "是否删除",
		width: "4%",
		dataIndex: "isDelete",
		customRender: function (isDelete) {
			switch (isDelete) {
			case 1:
				return '否'
			case 0:
				return '是'
			}
		}
	},
	{
		title: "操作",
		dataIndex: "action",
		key: "action",
		width: "150",
		fixed: "right",
		scopedSlots: { customRender: "action" },
	},
]
export const options = {
	isTrue: [
		{ id:1,name:'是'},
		{ id:0,name:'否'},
		{ id:null,name:'全部'},
	]
}