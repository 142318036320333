import httpService from "@/request"

// 动态列表
export function dynamicList(params) {
	return httpService({
		url: `/user/dynamic/list`,
		method: 'get',
		params: params,
	})
}
export function dynamicInfo(params) {
	return httpService({
		url: `/user/dynamic/findById`,
		method: 'get',
		params: params,
	})
}
// 删除
export function dynamicDel(params) {
	return httpService({
		url: `/user/dynamic/delete`,
		method: 'post',
		data: params,
	})
}
// 恢复
export function dynamicRecover(params) {
	return httpService({
		url: `/user/dynamic/recover`,
		method: 'post',
		data: params,
	})
}
// 评论list
export function commentList(params) {
	return httpService({
		url: `/user/comment/list`,
		method: 'get',
		params: params,
	})
}
// 删除
export function commentDel(params) {
	return httpService({
		url: `/user/comment/delete`,
		method: 'post',
		data: params,
	})
}
// 恢复
export function commentRecover(params) {
	return httpService({
		url: `/user/comment/recover`,
		method: 'post',
		data: params,
	})
}
